<template>
  <div class="text-left">
    <validation :activation="activeAlert" v-if="activeAlert" :info="donnes" @oga="retourAlert"/>
    <peniche :activation="activePeniche" v-if="activePeniche" :info="donnes" :donnes="selectedItem" @oga="retourPeniche"/>
      <div class="note p-4 bg-white flex">
        <div class="w-2/3">
          <div class="flex w-1/2">
            <bouton label="Validation en attente" size="10px" height="25px" background="rgba(251, 167, 5, 0.2)" v-if="donnes.state === 'CREATED'" color="#FBA705" class="w-1/3 mr-6"/>
            <bouton label="Coodination" size="10px" height="25px" background="#DCD7FC" color="#5138EE" class="w-1/4" v-if="donnes.state === 'CREATED'"/>
            <bouton label="Technique" size="10px" height="25px" background="#CEECEB" color="#099E9C" class="w-1/4" v-if="donnes.state === 'TECHNIQUE'"/>

          </div>

          <div class="text-c24 font-c5 mt-4">
            Commande Nº {{donnes.reference}} <span v-if="donnes.houseboat"> <span class="text-90">|</span> No Péniche: <span class="text-90">{{donnes.houseboat}}</span> </span>
          </div>
        </div>

        <div class="w-1/3 flex justify-end items-center">
<!--          <bouton label="Rejeter" size="18px" height="54px" background="#F82626" radius="5px" color="#fff" class="w-1/2 mr-6"  @oga="isAccept = false; activeAlert = true "/>-->
          <bouton label="Valider" size="18px" v-if="donnes.state !== 'TECHNIQUE' && donnes.state !== 'ACOUNTING'" height="54px" background="#2B9E7C" radius="5px" color="#fff" class="w-1/2" @oga="activeAlert = true "/>
        </div>

      </div>
  </div>
</template>

<script>
import valid from '../../../assets/icons/active.svg'
import bouton from '../../helper/add/button'
import validation from '../../popup/validation'
import peniche from '../../popup/peniche'

export default {
  name: "Index",

  components: {
    bouton,
    validation,
    peniche
  },
  props: {
    donnes: {
      type: Object,
      default: null
    },
    position: {
      type: Number,
      default: null
    },
    index: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
      icons: {
        valid
      },
      activeAlert: false,
      isAccept: false,
      selectedItem: null,
      activePeniche: false
    }
  },

  created () {
  },

  methods: {
    retourAlert (answer) {
      if (answer !== false) {
        this.activePeniche = true
        this.selectedItem = answer
      }
      this.activeAlert = false
    },

    retourPeniche (answer) {
      this.activeAlert = answer
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";
.svg-icon {
  fill: transparent;
}
.note{
  border-radius: 5px;
  border-left: 5px solid #FBA705
}
.base{
  border-left: 1px solid #C4C4C4;
}
.bord{
  border-left: 1px solid #2B9E7C;
}
.rond {
  border: 3px solid #C4C4C4;
}
</style>
