<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full pt-6"
        >
          <div class="text-left flex pl-6 pr-6">
            <div
              class="text-c24 font-c7 w-4/5"
            >
              A FAIRE
            </div>
            <div class="w-1/5 flex justify-end">
              <icon
                :data="icons.fermer"
                height="30"
                width="30"
                class="cursor-pointer"
                original
                @click="fermer"
              />
            </div>
          </div>

          <div class="text-c18 font-c4 mt-6 bg-white p-6 pb-12">
            <div class="text-c18 font-c6">
              Réception des articles
            </div>

            <div class="flex items-center text-c18 mt-5">
              <radio @info="retourEtat" />
              <div class="ml-5">
                Tous les articles sont en état
              </div>
            </div>

            <div class="text-c18 font-c6 mt-6">
              Vérifications effectuées
            </div>

            <div class="flex items-center text-c18 mt-5">
              <radio @info="retourConform" />
              <div class="ml-5">
                Conformité articles / ordonnance
              </div>
            </div>

            <div class="flex items-center text-c18 mt-5">
              <radio
                @info="retourPrix"
                :value-t="true"
                :error="state"
              />
              <div
                class="ml-5 text-black"
                :class="{'erro': state !== null}"
              >
                Conformité prix
              </div>
            </div>

            <textare
              placeholder="Votre message ici..."
              class="mt-4"
              radius="5px"
              height="130px"
              @info="retourNote"
            />

            <div
              v-if="error !== null"
              class="mt-6 text-red text-center text-c13"
            >
              {{ error }}
            </div>
            <div class="diviser mt-4" />

            <div class="w-full flex mt-4">
              <buton
                class="w-1/2"
                label="Invalider la vente"
                radius="5px"
                height="45px"
                size="16px"
                background="#F82626"
                :charge="charge"
                @oga="retourInvalid"
              />

              <buton
                v-if="!response.etat || !response.conform || !response.prix || state !== null "
                class="w-1/2 ml-4"
                label="Valider la vente"
                size="16px"
                radius="5px"
                height="45px"
                background="#EAEAEF"
                color="#606060"
              />
              <buton
                v-if="response.etat && response.conform && response.prix && state === null"
                class="w-1/2 ml-4"
                label="Valider la vente"
                size="16px"
                radius="5px"
                height="45px"
                background="#2B9E7C"
                :charge="charge"
                @oga="retourValid"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import ic_agence from '../../assets/icons/agenceGray.svg'
import fermer from '../../assets/icons/fermer.svg'
import textare from '../helper/form/textarea'
import http from "../../plugins/https"
import apiroutes from "../../router/api-routes"
import radio from '../helper/form/radio'

export default {
  name: 'Success',
  components: {
    PopupBase,
    buton,
    textare,
    radio
  },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donne: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      wantToMakeOffer: false,
      icons: {
        ic_agence,
        fermer
      },
      error: null,
      charge: false,
      response: {
        etat: false,
        conform: false,
        prix: false,
        note: '',
      },
      state: null
    }
  },

  created() {
    console.log('infooooo', this.info)
    this.wantToMakeOffer = this.activation
    const data = this.info.estimate.articles
    for (let item in data) {
      if (data[item].state === 'REJECTED') {
        this.state = 'yaa'
      }
    }
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    retourNote (answer) {
      this.response.note = answer
    },

    retourEtat (answer) {
      this.response.etat = answer
    },
    retourConform (answer) {
      this.response.conform = answer
    },
    retourPrix (answer) {
      this.response.prix = answer
    },

    retourValid () {
      if (this.response.etat && this.response.conform && this.response.prix){
        const data = this.info.estimate.articles

        let isExist = false

        for (let item in data) {
          if (data[item].product.type === 'GLASS') {
            isExist = true
          }
        }

        if (isExist) {
          this.$emit('oga', this.response)
        } else {
          this.validate()
        }

      }
    },

    validate () {
        this.charge = true
        http.post(apiroutes.baseURL + apiroutes.validation, {
          id: this.info.id,
          orderState: 'DELIVERED',
          state: 'TECHNIQUE_VALIDATION',
          meta: {},
          comment: this.response.note,
        })
            .then(response => {
              console.log(response)
              this.charge = false
              this.$router.push('/commandes')
            })
            .catch(error => {
              this.charge = false
              console.log(error)
            })
    },

    retourInvalid () {
      this.charge = true
      this.validation ('COORDINATION_REJECT')
    },

    validation (status) {
      if (this.response.note !== '') {
        let body = {
          id: this.info.id,
          state: status,
          meta: {},
          comment: this.response.note
        }
        if (status === 'COORDINATION_REJECT'){
          body.orderState = 'COORDINATION_REJECT'
        }

        http.post(apiroutes.baseURL + apiroutes.validation, body)
            .then(response => {
              console.log(response)
              this.charge = false
              if (status === 'COORDINATION_REJECT'){
                this.$router.push('/commandes')
              } else {
                this.$emit('oga', this.response)
              }
            })
            .catch(error => {
              this.charge = false
              console.log(error)
            })
      } else {
        this.charge = false
        this.error = 'Veuillez ajouter une note pour continuer'
      }
    },


    logState (answer) {
      this.wantToMakeOffer = answer
      this.fermer()
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 42%;
    }

    .diviser{
      height: 1px;
      background-color: #dddddd;
    }
    .erro {
      color: red;
      text-decoration:line-through
    }
    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: #F5F5F5;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .button {
        width: 100%;
        font-size: 16px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
