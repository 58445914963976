<template>
  <div class="text-left">
    <div class="mt-10">
      <div class="mt-10">
        <div>Assureurs/Maisons partenaires</div>
        <div class="mt-2 bg-f5 rounded-5 inputo flex pl-4 pr-4 items-center">
          <div v-for="(item, index) in donnes.insuranceSupports" :key="index">
            <bouton
                class="mr-2"
                :label="item.insurance.name"
                padding="0px 10px 0px 10px"
                height="30px"
                border="0.5px solid #DDDDDD"
                size="12px"
                background="#E9FFEE"
                color="black"
                weight="400"
                radius="4px"
            />
          </div>
        </div>
      </div>


      <div class="mt-10">
        <div>Note</div>
        <div class="mt-2 bg-f5 rounded-5 h-108 p-4">
          <span v-if="donnes.meta !== null && donnes.meta.note !== undefined">{{donnes.meta.note}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bouton from '../../helper/add/button'

export default {
  name: "Index",

  components: {
    bouton
  },
  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
    }
  },

  created () {
  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.inputo {
  background: #FFFFFF;
  border: 0.5px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 5px;
  height: 50px;
}

</style>
